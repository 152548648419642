import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-virtual-assistant',
  templateUrl: './virtual-assistant.component.html',
  styleUrls: ['./virtual-assistant.component.scss']
})
export class VirtualAssistantComponent implements OnInit {

  public isHideComponent = false;

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
    this._getActivatedRouter();
  }

  private _getActivatedRouter = (): void  => {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(({ url }: NavigationEnd) => {
        this.isHideComponent = url.includes('dashboard');
    });
  }

}
