import { Pipe, PipeTransform } from '@angular/core';

import { InvoiceStatusEnum } from '../enum/invoice-status.enum';
import { EnumStatusFatura } from '../models/extrato';
import { IInvoiceConfigStatus } from '../models/status-invoice-config-model';

@Pipe({
  name: 'statusFlagPayment',
})
export class StatusFlagPaymentPipe implements PipeTransform {
  private readonly _statusDetailsInvoice: Record<
    Exclude<EnumStatusFatura, 5 | 6 | 7>,
    InvoiceStatusEnum
  > = {
    1: InvoiceStatusEnum.PAGO,
    2: InvoiceStatusEnum.REFINANCIADO,
    3: InvoiceStatusEnum.ATRASO,
    4: InvoiceStatusEnum.PAGO,
    8: InvoiceStatusEnum.ABERTO,
    9: InvoiceStatusEnum.ABERTO,
  };

  private readonly _iconPath: string =
    '../../../../static/assets/icon/Categorias/';

  private readonly _iconPathInvoiceStatus: Record<
    keyof typeof InvoiceStatusEnum,
    string
  > = {
    PAGO: `${this._iconPath}icon-pago.svg`,
    REFINANCIADO: `${this._iconPath}icon-pago.svg`,
    ABERTO: `${this._iconPath}icon-aberto.svg`,
    EMABERTO: `${this._iconPath}icon-aberto.svg`,
    FATURAABERTA: `${this._iconPath}icon-aberto.svg`,
    VENCEHOJE: `${this._iconPath}icon-aberto.svg`,
    ATRASO: `${this._iconPath}icon-atraso.svg`,
    EMATRASO: `${this._iconPath}icon-atraso.svg`,
  };

  private readonly _invoiceStauts: Record<
    keyof typeof InvoiceStatusEnum,
    IInvoiceConfigStatus
  > = {
    PAGO: {
      label: 'Pago',
      classStyle: 'positive',
      icon: this._iconPathInvoiceStatus['PAGO'],
    },
    REFINANCIADO: {
      label: 'Refinanciado',
      classStyle: 'positive',
      icon: this._iconPathInvoiceStatus['REFINANCIADO'],
    },
    ABERTO: {
      label: 'Em Aberto',
      classStyle: 'informative',
      icon: this._iconPathInvoiceStatus['ABERTO'],
    },
    EMABERTO: {
      label: 'Em Aberto',
      classStyle: 'informative',
      icon: this._iconPathInvoiceStatus['EMABERTO'],
    },
    FATURAABERTA: {
      label: 'Em Aberto',
      classStyle: 'informative',
      icon: this._iconPathInvoiceStatus['FATURAABERTA'],
    },
    VENCEHOJE: {
      label: 'Em Aberto',
      classStyle: 'informative',
      icon: this._iconPathInvoiceStatus['VENCEHOJE'],
    },
    ATRASO: {
      label: 'Em Atraso',
      classStyle: 'negative',
      icon: this._iconPathInvoiceStatus['ATRASO'],
    },
    EMATRASO: {
      label: 'Em Atraso',
      classStyle: 'negative',
      icon: this._iconPathInvoiceStatus['EMATRASO'],
    },
  };

  public transform = (
    status: string | number,
    category?: 'detailsInvoice' | 'otherContext'
  ): IInvoiceConfigStatus => {
    // Adicionando condicional para corrigir temporariamente valor invalido do ID_STATUS retornado pela api para faturas que estão aguardando fechamento
    if (status === 0) {
      return this._invoiceStauts[this._statusDetailsInvoice['9']];
    }

    if (status && category === 'detailsInvoice') {
      return this._invoiceStauts[this._statusDetailsInvoice[status]];
    }

    return this._invoiceStauts[this._parseInvoiceStatus(status as string)];
  };

  private _parseInvoiceStatus = (status: string): string =>
    status.toUpperCase().replace(/\s/g, '');
}
