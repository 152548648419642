<div class="modal-membership mt-2">
  <div class="modal-body text-center">
    <div class="adesao-modal">
      <div class="row justify-content-center mb-2">
        <div class="col-12 text-center">
          <h3 class="bold title-header">Acesse sua nova fatura!</h3>
        </div>
      </div>
      <div class="col-12 wrapper-content">
        <div id="carousel-item" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators d-flex align-items-center">
            <li data-target="#carousel-item" attr.data-slide-to="{{ i }}" class="{{ (i === 0) ? 'active' : '' }}"
              *ngFor="let item of dataCarrouselDigitalInvoice$ | async; let i = index">
            </li>
          </ol>
          <div class="carousel-inner">
            <div id="{{ item.idItem }}" [attr.class]="item.class" *ngFor="let item of dataCarrouselDigitalInvoice$ | async">
              <p [innerHtml]="item.titleModal" class="title"></p>
              <img class="icon" [src]="item.imagePath">
              <div class="carousel-caption">
                <p [innerHtml]="item.descriptionModal" class="description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer-modal text-center">
    <button type="button" class="btn btn-primary btn-confirm" (click)="confirmDigitalInvoice()">Faça adesão</button>
  </footer>
</div>