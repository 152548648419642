import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Topics } from '@app/shared/models/topics.interface';
import { AlterModalService } from '@app/core/services/alter-modal.service';

@Component({
  selector: 'app-modal-information-security-policy',
  templateUrl: './modal-information-security-policy.component.html',
  styleUrls: ['./modal-information-security-policy.component.scss']
})
export class ModalInformationSecurityPolicyComponent implements OnInit {

  private _topicsCyberSecurity$: Observable<Array<Topics>>;
  private _topicsSecurityObjectives$: Observable<Array<Topics>>;
  private _subtTopicsGuidelines$: Observable<Array<Topics>>;
  private _topicsGuidelines: Observable<Array<Topics>>;

  constructor(
    private alterModalService: AlterModalService,
  ) { }

  ngOnInit() {
    this.$topicsCyberSecurity = this.mountTopicsCyberSecurity();
    this.$topicsSecurityObjectives = this.mountTopicsCyberSecurityObjectives();
    this.$subtTopicsGuidelines = this.mountSubTopicsGuidelines();
    this.$topicsGuidelines = this.mountTopicsGuidelines();
  }

  public mountTopicsCyberSecurity = (): Observable<Array<Topics>> => (
    of([
       { topic: 'Na <strong>cred-system</strong>, desenvolvemos soluções simples, digitais e seguras para tornarmos novas conquistas possíveis aos nossos parceiros lojistas e consumidores finais.' },
       { topic: 'Valorizamos nossos clientes e entendemos o quanto a segurança das informações é importante para aderirem às nossas soluções com confiança.' },
       { topic: 'A segurança de dados está entre as prioridades da cred-system, por isso, disponibilizamos abaixo um resumo da nossa Política de Segurança da Informação para que você possa conhecer brevemente as diretrizes aplicadas internamente para proteger os seus dados.' },
    ])
  )

  public mountTopicsGuidelines = (): Observable<Array<Topics>> => (
    of([
      { topic: 'Aplicar a estratégia de defesa em profundidade por meio da implementação de mais de uma camada de segurança, gerando maior garantia em relação a possíveis comprometimentos de alguma das camadas anteriores;' },
      { topic: 'Manter a capacidade de prevenir, detectar e reduzir a vulnerabilidade a incidentes relacionados com o ambiente cibernético, utilizando-se de registros de rastreabilidade da manipulação de dados da companhia e de seus clientes;' },
      { topic: 'Assegurar que os dados da empresa e de seus clientes sejam acessados e manipulados apenas por pessoas autorizadas e de forma segura;' },
      { topic: 'Proteger ativos tecnológicos e estabelecer procedimentos de monitoramento das redes da organização e das máquinas de colaboradores para a detecção de intrusões;' },
      { topic: 'Conduzir o monitoramento e a resposta de incidentes, seguindo as etapas de detecção, mitigação emergencial e análise de causa raiz;' },
      { topic: 'Elaborar cenários de incidentes para a realização periódica de testes de continuidade;' },
      { topic: 'Garantir a conscientização do time interno por meio de treinamentos mandatórios e avaliações periódicas.' },
    ])
  )

  public mountTopicsCyberSecurityObjectives = (): Observable<Array<Topics>> => (
    of([
      { topic: 'Confidencialidade: Apenas usuários autorizados têm permissão para acessar suas informações;' },
      { topic: 'Integridade: Apenas alterações autorizadas podem ser realizadas na informação armazenada;' },
      { topic: 'Disponibilidade: A informação deve ser disponibilizada apenas para usuários autorizados quando solicitado.' },
    ])
  )

  public mountSubTopicsGuidelines = (): Observable<Array<Topics>> => (
    of([
      { topic: 'Informação confidencial;' },
      { topic: 'Informação restrita ou de uso interno;' },
      { topic: 'Informação pública.' },
    ])
  )

  public modalHide = (): void => {
    this.alterModalService.modalHide();
  }

  public get $topicsCyberSecurity(): Observable<Array<Topics>> {
    return this._topicsCyberSecurity$;
  }

  public set $topicsCyberSecurity(value: Observable<Array<Topics>>) {
    this._topicsCyberSecurity$ = value;
  }

  public get $topicsSecurityObjectives(): Observable<Array<Topics>> {
    return this._topicsSecurityObjectives$;
  }

  public set $topicsSecurityObjectives(Value: Observable<Array<Topics>>) {
    this._topicsSecurityObjectives$ = Value;
  }

  public get $subtTopicsGuidelines(): Observable<Array<Topics>> {
    return this._subtTopicsGuidelines$;
  }

  public set $subtTopicsGuidelines(value: Observable<Array<Topics>>) {
    this._subtTopicsGuidelines$ = value;
  }

  public get $topicsGuidelines(): Observable<Array<Topics>> {
    return this._topicsGuidelines;
  }

  public set $topicsGuidelines(value: Observable<Array<Topics>>) {
    this._topicsGuidelines = value;
  }

}
