import { Injectable } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class AlterModalService {
  private _modalRef: BsModalRef;

  constructor(private _modalService: BsModalService) {}

  public showModal = (
    modalComponent: any,
    timeOut: number,
    stateModal?: any,
    classStyle?: string,
    ignoreBackdropClick?: boolean,
    keyboard?: boolean
  ): void => {
    setTimeout(
      () =>
        (this._modalRef = this._modalService.show(modalComponent, {
          initialState: stateModal,
          class: classStyle,
          ignoreBackdropClick: ignoreBackdropClick,
          keyboard: keyboard,
        })),
      timeOut
    );
  };

  public modalHide = (): void => {
    this._modalRef.hide();
  };

  public get initialState() {
    return this._modalService.config.initialState;
  }
}
