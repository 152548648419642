<div class="modal-header">
  <h3 class="title bold">Selecionar Cartão:</h3>
  <button
    type="button" 
    class="close" 
    (click)="onHideModal()"
  >
    <span aria-hidden="true">
      <img src="./static/assets/icon/Basics/btn_fechar.svg">
    </span>
  </button>
</div>
<div class="modal-body">
  <div
    class="card credit-card-item"
    *ngFor="let card of cards"
    [ngClass]="{ 'active': card?.id === selectedCard?.id }"
  >
    <div class="card-body" (click)="selectCard(card)">
      <div class="client-card">
        <div class="client-card-logo">
          <div class="card-img">
            <img [src]="card.logo" *ngIf="card.logo">
          </div>
          <div class="client-card-data">
            <p>{{ card.nome | titlecase }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-modal">
  <button
    type="button"
    class="btn btn-outline btn-primary" 
    data-dismiss="modal" 
    (click)="onHideModal()"
  >
    Cancelar
  </button>
  <button
    type="button" 
    class="btn btn-primary"
    [disabled]="!isSelectedCard" 
    (click)="setSelectedCreditCard()"
  >
    Continuar
  </button>
</div>