import { StorageService } from '../../services/storage.service';

export abstract class BaseGuard {
  constructor(protected _storage: StorageService) {}

  protected _token = (): boolean => !!this._storage.token;

  protected _isLogged = (): boolean => this._storage.loginTracking;

  protected _isUserLogged = (): boolean => this._token() && this._isLogged();

  protected _isLoggedInFastTicket = (): boolean =>
    this._storage.fastTicketLoginTracking;

  protected _isAllowedToAccessModule = (value: string): boolean => {
    return this._storage.allowedModules[value] || false;
  };
}
