import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { StorageService } from '../../../../services/storage.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public envVersion: string = environment.version;
  public isFasTicketRouter: boolean = false;
  private readonly _ROUTER_FAST_TICKET: string =
    '/boleto-rapido/boletos-abertos';

  constructor(private _router: Router, private _storage: StorageService) {}

  ngOnInit() {
    this._getRouterEvents();
  }

  private _getRouterEvents = (): void => {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe({
        next: ({ urlAfterRedirects }: NavigationEnd) => {
          this.isFasTicketRouter =
            urlAfterRedirects === this._ROUTER_FAST_TICKET;
        },
      });
  };

  public logout = (): void => {
    this._storage.resetAllStorages();
    this._router.navigate(['/']);
  };
}
