<app-modal-header-lgpd-terms>
</app-modal-header-lgpd-terms>
<main>
    <section>
        <h1 class="title mt-6">Política de cyber segurança</h1>
        <p class="mt-3" *ngFor="let itemList of $topicsCyberSecurity | async" [innerHtml]="itemList.topic"> 
        </p>
    </section>
    <section>
        <h3 class="title-section mt-3 mb-3">Objetivos de segurança</h3>
        <p class="mt-3 mb-3">
            O objetivo da nossa Política de Segurança da Informação é garantir que os três pilares fundamentais da segurança sejam aplicados por meio da:
        </p>
        <ol>
            <li *ngFor="let itemList of $topicsSecurityObjectives | async">
                {{ itemList.topic }}
            </li>
        </ol>
    </section>
    <section>
        <h3 class="title-section mt-3 mb-3">Diretrizes</h3>
        <p class="mt-3 mb-3">
            O objetivo da nossa Política de Segurança da Informação é garantir que os três pilares fundamentais da segurança sejam aplicados por meio da:
        </p>
        <p>
            1. Classificar dados de acordo com sua criticidade e sensibilidade para o negócio da empresa e seus clientes, considerando os níveis abaixo:
        </p>
        <ul>
            <li *ngFor="let itemList of $subtTopicsGuidelines | async">
                {{ itemList.topic }}
            </li>
        </ul>
        <ol start="2" class="mt-3">
            <li *ngFor="let itemList of $topicsGuidelines | async">
                {{ itemList.topic }} 
            </li>
        </ol>
        <p class="mt-3 mb-3">
            Importante: A Política de Segurança da Informação da <strong>cred-system</strong> é revisada pelo menos uma vez ao ano a fim de garantir um material atualizado e em linha com as exigências do mercado. Aqui, os seus dados estão seguros!
        </p>
    </section>
</main>