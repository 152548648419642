<div class="termination-modal mt-4">
  <div class="modal-body">
    <div class="row justify-content-center wrapper-content">
      <div class="col-12 text-center mb-3">
        <img
          [src]="'static/assets/icon/Basics/icon-sucesso.svg'"
          class="image-titulo mb-5"
          alt="Parabéns!"
        />
        <p class="title-modal">Parabéns!</p>
      </div>
      <div class="col-12 text-center faca-adesao">
        <p class="description-modal">
          A partir de agora, a
          <strong
            >fatura de todos os seus cartões administrados pela
            credsystem</strong
          >
          serão enviadas exclusivamente para o e-mail e/ou celular cadastrado.
        </p>
      </div>
    </div>
    <hr />
    <div class="wrapper-content-description-footer">
      <ng-container *ngIf="hasCard; else emptyCards">
        <ng-container *ngIf="listCards$ | async as cards; else loading">
          <div class="list-cards">
            <div class="description-footer">
              Selecione abaixo o cartão desejado<br />
              e prossiga para sua fatura.
            </div>
            <div class="row justify-content-center footer-modal">
              <div class="col-12 text-center">
                <app-carrousel-cards
                  (cardSelect)="setCard($event)"
                  [listCards]="cards"
                >
                </app-carrousel-cards>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #emptyCards>
  <div class="empty-list-cards">
    <div class="title-description-footer">
      Você não possui cartão disponível no momento. Tente novamente mais tarde.
    </div>
    <div class="subtitle-description-footer mt-3">
      Iremos te direcionar para o início, mas você poderá tentar novamente mais
      tarde.
    </div>
    <footer class="footer-modal text-center">
      <button
        type="button"
        class="btn btn-primary btn-confirm"
        (click)="hideModal()"
      >
        OK!
      </button>
    </footer>
  </div>
</ng-template>

<ng-template #loading>
  <ngx-skeleton-loader
    count="1"
    [theme]="{ height: '100px' }"
  ></ngx-skeleton-loader>
</ng-template>
