// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlBase: 'https://apihml.portalcredsystem.com.br',
  urlApi: 'https://apihml.portalcredsystem.com.br/fatura/v1',
  urlApiToken: 'https://apihml.portalcredsystem.com.br/credsystem-tokenizacao-cliente',
  urlApiNegociacao: 'https://apihml.portalcredsystem.com.br/cobranca-digital/v2',
  urlApiCartaoCliente: 'https://apihml.portalcredsystem.com.br/cliente-cartao/v2',
  urlApiValidacao: 'https://apihml.portalcredsystem.com.br/sso-clientes/v4',
  urlEmissaoCartao: 'https://apihml.portalcredsystem.com.br/credsystem-tombamento',
  urlApiIndex: 'https://apihml.portalcredsystem.com.br/portal-cartao/v1',
  urlApiSeguros: 'https://apihml.portalcredsystem.com.br/portal-cartao/v1',
  version: '4.55.0',
  gtm_src: '',
  gtm_container_id: '',
  gtm_auth: '',
  gtm_preview: '',
  allowmeUrl: 'https://stage-sdk-js.allowme.com.br/v2/allowme.min.js',
  allowmeKey: 'V8xxeV7YhYFVCDdeQMjWMmL1xrA4rYXDnixt1aUK',
  recaptcha: '6Le81N8oAAAAAAoZuoojIoe7lxlDTIf2TbnTrmrB'
};

// Para testar com WireMock (executar: npm rum mock)
// Irá sobrescrever as configurações feitas acima
//environment.urlBase = 'http://localhost:8080';