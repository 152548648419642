<div class="circle-wrap">
  <div class="circle">
    <div class="mask full">
      <div class="fill"></div>
    </div>

    <div class="mask half">
      <div class="fill" ></div>
    </div>

    <div class="inside-circle">{{ timerLoading }}</div>
  </div>
</div>
