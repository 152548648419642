import { Injectable } from '@angular/core';

import { IUserFastTicket } from '../modules/boleto-rapido/shared/models/user.interface';
import { IUser } from '@app/shared/models/cliente.model';
import { ICreditCard } from '@app/shared/models/credit-card.model';
import { BannerContextIndexInterface } from '@app/core/models/banners-context-index.interface';
import { IEligibleNegotiation } from '@app/modules/negociacao/shared/models/IEligibleNegotiation';
import { OpcaoParcela } from '@app/shared/models/parcela.model';
import { FlowsType } from '@app/core/enum/flows';
import { ModulesContextIndexInterface } from '@app/core/models/modules-context-index.interface';
import { IModules } from '@app/core/models/IModules';
import { IUserLogin } from '@app/core/models/IUserLogin';
import { DataBoleto, IBoletoCarne } from '@app/shared/models/boleto.model';
import { IResponseDetailsExtratct } from '@app/modules/fatura/shared/models/IExtract';
import { IInvoiceFlowPayment } from '@app/core/models/IFlowPayment';
import { PaymentType } from '@app/core/models/IOptionPayment';
import { InvoicePaymentMethodsType } from '@app/core/models/IPaymentMethods';
import { IResponseTicketBooklet } from '@app/modules/pagameto/models/IResponseTicketBooklet';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public get token(): string {
    return sessionStorage.getItem('token');
  }

  public set token(token: string) {
    sessionStorage.setItem('token', token);
  }

  public get bin(): any {
    return sessionStorage.getItem('bin');
  }

  public set bin(dataCartao) {
    sessionStorage.setItem('bin', dataCartao);
  }

  public get tipoCartao(): string {
    return sessionStorage.getItem('tipoCartao');
  }

  public set tipoCartao(dataCartao: string) {
    sessionStorage.setItem('tipoCartao', dataCartao);
  }

  public get numeroCartao(): string {
    return sessionStorage.getItem('numeroCartao');
  }

  public set numeroCartao(dataCartao: string) {
    sessionStorage.setItem('numeroCartao', dataCartao);
  }

  public get numeroExtrato(): string {
    return sessionStorage.getItem('numeroExtrato');
  }

  public set numeroExtrato(dataExtrato: string) {
    sessionStorage.setItem('numeroExtrato', dataExtrato);
  }

  public get nomeCartao(): string {
    return sessionStorage.getItem('nomeCartao');
  }

  public set nomeCartao(dataCartao: string) {
    sessionStorage.setItem('nomeCartao', dataCartao);
  }

  public set cartaoSelecionado(value: ICreditCard) {
    sessionStorage.setItem('cartaoSelecionado', JSON.stringify(value));
  }

  public get cartaoSelecionado(): ICreditCard {
    return JSON.parse(sessionStorage.getItem('cartaoSelecionado'));
  }

  public get elegivelNegociacao(): boolean {
    return JSON.parse(sessionStorage.getItem('elegivelNegociacao'));
  }

  public set elegivelNegociacao(data) {
    sessionStorage.setItem('elegivelNegociacao', JSON.stringify(data));
  }

  public resetAllStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public get dataUser(): IUser {
    return (
      JSON.parse(sessionStorage.getItem('dataUser')) || {
        celular: '',
        email: '',
        nome: '',
        faturaDigital: false,
        ddd: '',
      }
    );
  }

  public set dataUser(data: IUser) {
    sessionStorage.setItem('dataUser', JSON.stringify(data));
  }

  public get cpf(): string {
    return sessionStorage.getItem('cpf');
  }

  public set cpf(value: string) {
    sessionStorage.setItem('cpf', value);
  }

  public clearItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public set moduloSelecionado(value: string) {
    sessionStorage.setItem('moduloSelecionado', value);
  }

  public get moduloSelecionado(): string {
    return sessionStorage.getItem('moduloSelecionado');
  }

  public set moduloNotificado(value: string) {
    sessionStorage.setItem('moduloNotificado', value);
  }

  public get moduloNotificado(): string {
    return sessionStorage.getItem('moduloNotificado');
  }

  public get modulos(): ModulesContextIndexInterface[] {
    return JSON.parse(sessionStorage.getItem('modulos'));
  }

  public set modulos(value: ModulesContextIndexInterface[]) {
    sessionStorage.setItem('modulos', JSON.stringify(value));
  }

  public get moduloAtivo(): string {
    return sessionStorage.getItem('moduloAtivo');
  }

  public set moduloAtivo(value: string) {
    sessionStorage.setItem('moduloAtivo', value);
  }

  public set usuario(value: IUserFastTicket) {
    sessionStorage.setItem('usuario', JSON.stringify(value));
  }

  public get usuario(): IUserFastTicket {
    return JSON.parse(sessionStorage.getItem('usuario'));
  }

  public get loginTracking(): boolean {
    return JSON.parse(sessionStorage.getItem('loginTracking'));
  }

  public set loginTracking(value: boolean) {
    sessionStorage.setItem('loginTracking', JSON.stringify(value));
  }

  public get trackingAgreement(): string {
    return sessionStorage.getItem('trackingAgreement');
  }

  public set trackingAgreement(value: string) {
    sessionStorage.setItem('trackingAgreement', value);
  }

  public get paymentModalReference() {
    return sessionStorage.getItem('paymentModalReference');
  }

  public set paymentModalReference(value: string) {
    sessionStorage.setItem('paymentModalReference', value);
  }

  public get fastTicketLoginTracking(): boolean {
    return JSON.parse(sessionStorage.getItem('fastTicketLoginTracking'));
  }

  public set fastTicketLoginTracking(value: boolean) {
    sessionStorage.setItem('fastTicketLoginTracking', JSON.stringify(value));
  }

  public get banners(): BannerContextIndexInterface[] {
    return JSON.parse(sessionStorage.getItem('banners'));
  }

  public set banners(value: BannerContextIndexInterface[]) {
    sessionStorage.setItem('banners', JSON.stringify(value));
  }

  public get debtEligibility(): IEligibleNegotiation {
    return JSON.parse(sessionStorage.getItem('debtEligibility'));
  }

  public set debtEligibility(value: IEligibleNegotiation) {
    sessionStorage.setItem('debtEligibility', JSON.stringify(value));
  }

  public get selectedInstallmentAgreement(): Partial<OpcaoParcela> {
    return JSON.parse(sessionStorage.getItem('selectedInstallmentAgreement'));
  }

  public set selectedInstallmentAgreement(value: Partial<OpcaoParcela>) {
    sessionStorage.setItem(
      'selectedInstallmentAgreement',
      JSON.stringify(value)
    );
  }

  public get flow(): FlowsType {
    return JSON.parse(sessionStorage.getItem('flow'));
  }

  public set flow(value: FlowsType) {
    sessionStorage.setItem('flow', JSON.stringify(value));
  }

  public get allowedModules(): Partial<IModules> {
    return JSON.parse(sessionStorage.getItem('allowedModules'));
  }

  public set allowedModules(value: Partial<IModules>) {
    sessionStorage.setItem('allowedModules', JSON.stringify(value));
  }

  public get currentUser(): IUserLogin {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  public set currentUser(value: IUserLogin) {
    sessionStorage.setItem('user', JSON.stringify(value));
  }

  public get isCampaignEligible(): boolean {
    return JSON.parse(sessionStorage.getItem('isCampaignEligible'));
  }

  public set isCampaignEligible(value: boolean) {
    sessionStorage.setItem('isCampaignEligible', JSON.stringify(value));
  }

  public get showModalCampaign(): boolean {
    return JSON.parse(sessionStorage.getItem('showModalCampaign'));
  }

  public set showModalCampaign(value: boolean) {
    sessionStorage.setItem('showModalCampaign', JSON.stringify(value));
  }

  public get limitValueCampaign(): string {
    return sessionStorage.getItem('limitValueCampaign');
  }

  public set limitValueCampaign(value: string) {
    sessionStorage.setItem('limitValueCampaign', value);
  }

  public get ticketAgreement(): DataBoleto {
    return JSON.parse(sessionStorage.getItem('ticketAgreement'));
  }

  public set ticketAgreement(value: DataBoleto) {
    sessionStorage.setItem('ticketAgreement', JSON.stringify(value));
  }

  public get detailsExtract(): IResponseDetailsExtratct {
    return JSON.parse(sessionStorage.getItem('detailsExtract'));
  }

  public set detailsExtract(value: IResponseDetailsExtratct) {
    sessionStorage.setItem('detailsExtract', JSON.stringify(value));
  }

  public get invoiceDueDate(): string {
    return sessionStorage.getItem('invoiceDueDate');
  }

  public set invoiceDueDate(value: string) {
    sessionStorage.setItem('invoiceDueDate', value);
  }

  public get invoicePaymentFlow(): Partial<IInvoiceFlowPayment> {
    return (
      JSON.parse(sessionStorage.getItem('invoicePaymentFlow')) || {
        'opcao-pagamento': false,
        'pagamento-fatura': false,
        boleto: false,
        pix: false,
      }
    );
  }

  public set invoicePaymentFlow(value: Partial<IInvoiceFlowPayment>) {
    sessionStorage.setItem('invoicePaymentFlow', JSON.stringify(value));
  }

  public get invoiceAmount(): number {
    return JSON.parse(sessionStorage.getItem('invoiceAmount'));
  }

  public set invoiceAmount(value: number) {
    sessionStorage.setItem('invoiceAmount', JSON.stringify(value));
  }

  public get invoiceBarcode(): string {
    return sessionStorage.getItem('invoiceBarcode');
  }

  public set invoiceBarcode(value: string) {
    sessionStorage.setItem('invoiceBarcode', value);
  }

  public get invoicePaymentMethod(): InvoicePaymentMethodsType {
    return JSON.parse(sessionStorage.getItem('invoicePaymentMethods'));
  }

  public set invoicePaymentMethod(value: InvoicePaymentMethodsType) {
    sessionStorage.setItem('invoicePaymentMethods', JSON.stringify(value));
  }

  public get invoiceInstallment() {
    return sessionStorage.getItem('invoiceInstallment');
  }

  public set invoiceInstallment(value: string) {
    sessionStorage.setItem('invoiceInstallment', value);
  }

  public get invoiceFormOfPayment(): PaymentType {
    return JSON.parse(sessionStorage.getItem('invoiceFormOfPayment'));
  }

  public set invoiceFormOfPayment(value: PaymentType) {
    sessionStorage.setItem('invoiceFormOfPayment', JSON.stringify(value));
  }

  public get booklet(): IBoletoCarne {
    return JSON.parse(sessionStorage.getItem('booklet'));
  }

  public set booklet(value: IBoletoCarne) {
    sessionStorage.setItem('booklet', JSON.stringify(value));
  }

  public get ticketBooklet(): IResponseTicketBooklet {
    return JSON.parse(sessionStorage.getItem('ticketBooklet'));
  }

  public set ticketBooklet(value: IResponseTicketBooklet) {
    sessionStorage.setItem('ticketBooklet', JSON.stringify(value));
  }

  public removeItem(keyName: string): void {
    sessionStorage.removeItem(keyName);
  }
}
