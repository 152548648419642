import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpStatusCode,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

import { StorageService } from '@app/services/storage.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private _storage: StorageService,
    private _toast: ToastrService,
    private _router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let nextRequest: HttpRequest<any> = !!this._storage.token
      ? this._setToken(request)
      : request;

    return next.handle(nextRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        const urlContext: Record<'fastTicket' | 'default', string[]> = {
          fastTicket: ['boleto-rapido'],
          default: ['sso', 'pre-login'],
        };

        if (this._isUnauthorizedUser(error) && !this._isIgnoreApis(error.url)) {
          this._storage.resetAllStorages();

          if (!this._isIgnoreToastEvents(error.url))
            this._toast.error('Usuário não autorizado');

          this._checkUrlFastTicketContext(error.url)
            ? this._navigate(urlContext['fastTicket'])
            : this._navigate(urlContext['default']);
        }

        return throwError(() => error);
      })
    );
  }

  // Function responsável por validar possíveis apis, com finalidade de evitar exibição de toast
  private _isIgnoreToastEvents = (urlApi: string): boolean => {
    const listApis: string[] = [
      '/respostas',
      '/acesso-rapido',
      '/boleto-rapido',
    ];

    return this._isCheckUrls(listApis, urlApi);
  };

  private _isUnauthorizedUser = (error: HttpErrorResponse): boolean =>
    error.status === HttpStatusCode.Unauthorized;

  private _checkUrlFastTicketContext = (url: string): boolean =>
    url.indexOf('acesso-rapido' || 'boleto-rapido') !== -1;

  private _isIgnoreApis = (urlApi: string): boolean => {
    const listApis: string[] = ['/login'];

    return this._isCheckUrls(listApis, urlApi);
  };

  private _isCheckUrls = (listApis: string[], urlApi: string): boolean =>
    listApis.some((strApi) => urlApi.includes(strApi));

  private _setToken = (request: HttpRequest<any>): HttpRequest<any> => {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this._storage.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  };

  private _navigate = (url: string[]): void => {
    this._router.navigate(url);
  };
}
