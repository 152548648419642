<header>
    <div class="content-logos">
        <div class="wrapper-logo">
            <img src="static/assets/images/portal_do_cartao_background_black.svg" alt="Portal do Cartão Web" class="logo-portal-cartao">
            <small>V.{{ $enviromentVersion  }}</small>
        </div>
        <img [src]="$pathLogo" alt="credsystem" class="logo-credsystem">
    </div>
    <span aria-hidden="true"
          (click)="modalHide()">
        <img src="./static/assets/icon/Basics/btn_fechar.svg">
    </span>
</header>