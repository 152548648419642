<header>
    <div class="close">
        <button
            type="button"
            (click)="modalHide()"
        >
            <img
                [src]="'./static/assets/icon/Basics/btn_fechar.svg'"
                alt="Feachar"
            >
        </button>
    </div>
    <img
        class="icon-alert"
        [src]="'../../../../../../static/assets/icon/Basics/icon-alert.svg'"
        Alt="Alerta de venda de carteira"
    />
    <h1>Atualização sobre seus débitos</h1>
</header>
<section class="description">
    <p>{{ soldWalletDescriptions.tituloMensagem }}</p>
    <p>{{ soldWalletDescriptions.descricaoMensagem }}</p>
</section>
<footer>
    <button
        type="button"
        class="btn btn-primary"
        (click)="modalHide()"
    >
        OK!
    </button>
</footer>