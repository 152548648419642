import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { canMatchUserLogged } from './core/guards/permissions';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';

const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canMatch: [canMatchUserLogged],
    data: {
      accessContext: 'DASHBOARD',
    },
  },
  {
    path: 'sso',
    loadChildren: () =>
      import('./modules/sso-login/sso-login.module').then(
        (m) => m.SSOLoginModule
      ),
  },
  {
    path: 'boleto-rapido',
    loadChildren: () =>
      import('./modules/boleto-rapido/fast-ticket.module').then(
        (m) => m.FastTicketModule
      ),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
  // TODO: Contexto da FAQ temporariamente comentado até revisão e ajustes de descrições do mesmo.
  /* {
    path: 'faq',
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule), //  
  }, */
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
