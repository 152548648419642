import { Observable, Subject } from 'rxjs';
import { ComponentEvent } from './event-component';

export abstract class ComponentEventHandler<eventT, dataT> {

    public eventEmmmiter: Subject<ComponentEvent<eventT, dataT>> = new Subject();

    constructor() { }

    public dispacthAction(action: ComponentEvent<eventT, dataT>): void {
        this.eventEmmmiter.next(action);
    }

    public onEvent(): Observable<any> {
        return this.eventEmmmiter.asObservable();
    }

}
