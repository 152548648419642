import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuDashboardService {
  private _typeContextCard$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor() {}

  public dispacthAction = (eventSelectedCard: boolean): void => {
    this._typeContextCard$.next(eventSelectedCard);
  };

  public onEvent = (): Observable<boolean> =>
    this._typeContextCard$.asObservable();
}
