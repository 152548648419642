import { Component, Input } from '@angular/core';

import { AlterModalService } from '@app/core/services/alter-modal.service';

import { ModalInformationSecurityPolicyComponent } from '../../../components/modal-information-security-policy/modal-information-security-policy.component';

import { environment } from '../../../../../environments/environment';
import { GoogleTagManager } from '@app/core/utils/google-tag-manager';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public version: string = `V.${environment.version}`;
  public dateNow = new Date();
  @Input() public isAccessingDashboard: boolean;
  private readonly _dispatchEvents: Record<
    | 'INFORMATION_SECURITY_POLICY'
    | 'PRIVACY_POLICY'
    | 'PRIVACY_PORTAL'
    | 'GOOGLE_PLAY'
    | 'APP_STORE'
    | 'ACCESS_OUR_SITE'
    | 'COOKIE_POLICY',
    () => void
  > = {
    INFORMATION_SECURITY_POLICY: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link de política de segurança da informação no contexto da home'
      ),
    PRIVACY_POLICY: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link de política de privacidade no contexto da home'
      ),
    PRIVACY_PORTAL: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link de portal da privacidade no contexto da home'
      ),
    GOOGLE_PLAY: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link do google play no contexto da home'
      ),
    APP_STORE: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link da app store no contexto da home'
      ),
    ACCESS_OUR_SITE: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link de acesse nosso site no contexto da home'
      ),
    COOKIE_POLICY: () =>
      GoogleTagManager.setGeneralHomeEventsAnalitycs(
        'Usuário clicou no link de política de cookies no contexto da home'
      ),
  };

  constructor(private _alterModalService: AlterModalService) {}

  public modalInformationSecurityPolicy = (): void => {
    this._alterModalService.showModal(
      ModalInformationSecurityPolicyComponent,
      0,
      null,
      'modal-xxl'
    );

    this.dispatchEventsAnalitycs('INFORMATION_SECURITY_POLICY');
  };

  public dispatchEventsAnalitycs = (event: string): void => {
    this._dispatchEvents[event]();
  };
}
