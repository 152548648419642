import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlterModalService } from '@app/core/services/alter-modal.service';

@Component({
  selector: 'app-modal-list-empty-cards',
  templateUrl: './modal-list-empty-cards.component.html',
  styleUrls: ['./modal-list-empty-cards.component.scss'],
})
export class ModalListEmptyCardsComponent {
  public readonly iconEmptyListCards: string =
    'static/assets/icon/Basics/icon-alert.svg';

  constructor(
    private _alterModal: AlterModalService,
    private _router: Router
  ) {}

  public hideModal = () => {
    this._alterModal.modalHide();

    this._router.navigate(['/']);
  };
}
