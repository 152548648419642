import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-empty-invoices',
  templateUrl: './list-empty-invoices.component.html',
  styleUrls: ['./list-empty-invoices.component.scss']
})
export class ListEmptyInvoicesComponent {

  constructor() { }

}
