import { Injectable, } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private _loader: NgxSpinnerService,
  ) { }

  show(title = 'Carregando...') {
    this._loader.show();
  }

  hide() {
    this._loader.hide();
  }

  public loaderShow = (): void => {
    this._loader.show();
  }

  public loaderHide = (): void => {
    this._loader.hide();
  }

}
