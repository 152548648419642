import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { StorageService } from '@app/services/storage.service';
import { CartaoService } from '@app/services/cartao-service.service';
import { RouterControlService } from '@app/services/router-control.service';
import { DigitalInvoiceService } from '@app/services/digital-invoice.service';
import { MenuDashboardService } from '@app/services/menu-dashboard.service';
import { AlterModalService } from '../../../core/services/alter-modal.service';
import { LoaderService } from '@app/services/loader.service';
import { RequestService } from '@app/core/services/request.service';
import { EncryptService } from '@app/services/encrypt.service';

import { ICreditCard } from '@app/shared//models/credit-card.model';
import { Utils } from '@app/core/utils/utils';
import { BaseLogin } from '@app/core/abstract-classes/base-login';

@Component({
  selector: 'app-modal-trocacartao',
  templateUrl: `./modal-trocacartao.component.html`,
  styleUrls: ['./modal-trocacartao.component.scss'],
})
export class ModalTrocaCartaoComponent
  extends BaseLogin
  implements OnInit, OnDestroy
{
  public cards: ICreditCard[];
  public selectedCard: ICreditCard;
  public isSelectedCard: boolean = false;

  constructor(
    private _menuDashboardService: MenuDashboardService,
    protected _router: Router,
    protected _storage: StorageService,
    protected _cartaoService: CartaoService,
    protected _digitalInvoiceService: DigitalInvoiceService,
    protected _alterModalService: AlterModalService,
    protected _loader: LoaderService,
    protected _routerControl: RouterControlService,
    protected _requestService: RequestService,
    protected _encrypt: EncryptService,
  ) {
    super(
      _loader,
      _storage,
      _cartaoService,
      _router,
      _routerControl,
      _alterModalService,
      _digitalInvoiceService,
      _requestService,
      _encrypt
    );
  }

  ngOnInit() {
    this._checkIsSelectedCard(this._storage.cartaoSelecionado);
    this.cards = Utils.setCards(
      this._alterModalService.initialState['listCards'] as ICreditCard[]
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll$.next(null);
    this._unsubscribeAll$.unsubscribe();
  }

  public selectCard = (card: ICreditCard): void => {
    this.selectedCard = card;
    this.isSelectedCard = true;
  };

  public setSelectedCreditCard = (): void => {
    this._loader.show();

    this._cartaoService.saveDataCardStorage(this.selectedCard);

    this._getIndex()
      .pipe(
        tap((value) => {
          this._standardFlow(value);
          this._dismiss();
        }),
        catchError(() => {
          this._modalHide();
          return EMPTY;
        }),
        finalize(() => this._loader.hide())
      )
      .subscribe();
  };

  public onHideModal = (): void => {
    if (this._isActiveDashboardModule()) {
      this._modalHide();
      return;
    }

    this._storage.resetAllStorages();
    this._modalHide();
    this._routeDispatch('/');
  };

  private _dismiss = (): void => {
    if (this._isInitStateLogin()) {
      this._router.navigateByUrl(this._routerControl.getRouteContext());
      this._modalHide();
      return;
    }

    this._dispacthChangeSelectedCard(true);
    this._setRouter();
    this._modalHide();

    this._loader.show('Configurando cartão...');

    setTimeout(() => {
      this._loader.hide();
    }, 2000);
  };

  private _checkIsSelectedCard = (value: ICreditCard): void => {
    this.selectedCard = value ? value : null;
    this.isSelectedCard = this.selectedCard ? true : false;
  };

  private _setRouter = (): void => {
    this._routeDispatch(
      this._routerControl.dispatchRouter(this._storage.tipoCartao)
    );
  };

  private _routeDispatch = (value: string): void => {
    this._router.navigateByUrl(value);
  };

  private _dispacthChangeSelectedCard = (eventSelectedCard: boolean): void => {
    this._menuDashboardService.dispacthAction(eventSelectedCard);
  };

  private _isActiveDashboardModule = (): boolean => !!this._storage.moduloAtivo;

  private _isInitStateLogin = (): boolean =>
    this._alterModalService.initialState['login'] as boolean;

  private _modalHide = (): void => {
    this._alterModalService.modalHide();
  };
}
