<div class="container text-center">
  <header class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <button
      type="button"
      class="close pull-right"
      (click)="modalHide()"
      *ngIf="!hasConfirmAcepptDigitalInvoice"
    >
      <span aria-hidden="true"
        ><img src="./static/assets/icon/Basics/btn_fechar.svg"
      /></span>
    </button>
  </header>
  <app-modaladesao (eventEmmiter)="confirmAcceptDigitalInvoice()">
  </app-modaladesao>
</div>
