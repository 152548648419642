import { Injectable } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { IPaymentDetails } from '@app/modules/pagameto/models/IPaymetDetails';
import { DataStateModalPaymentInterface } from '@app/shared/models/data-state-modal-payment.interface';
import { DataCardTransaction } from '@app/shared/models/data-card-transiction.inteface';
import { PagSeguroInterface } from '../models/pagseguro.interface';
import { DataParcel } from '../models/data-parcel.interface';
import { IBoletoCarne } from '@app/shared/models/boleto.model';

declare const PagSeguro: PagSeguroInterface;

@Injectable({
  providedIn: 'root',
})
export class ModalPaymentService {
  public modalRef: BsModalRef;
  private _initStateModalSelectPayment: DataStateModalPaymentInterface;
  private _datePayment: string;
  private _valuePayment: number;
  private _usefulExpirationDate: Date;
  private _usefulExpirationDateString: string;
  private _installmentsDescription: string;
  private _numberOfInstallments: string;
  private _installmentsValue: string;
  private _numberExtract: string;
  private _totalValue: string;
  private _barCode: string;
  private _customMinimumValue: number;
  private _paymentTypeFlow: string;
  private _pdf: string | string[];
  private _avaiblePaymentCreditCard: IPaymentDetails;
  private _securePayment: PagSeguroInterface;
  private _payloadHash: string;
  private _dataParcel: DataParcel;
  private _booklet: IBoletoCarne;

  constructor(private _modalService: BsModalService) {}

  public showModal = (
    modalComponent: any,
    timeOut: number,
    stateModal?: any,
    classStyle?: string
  ): void => {
    setTimeout(
      () =>
        (this.modalRef = this._modalService.show(modalComponent, {
          initialState: stateModal,
          class: classStyle,
        })),
      timeOut
    );
  };

  public modalHide = (): void => {
    this.modalRef.hide();
  };

  public encryptCard = (dataCard: DataCardTransaction): string =>
    PagSeguro.encryptCard(dataCard).encryptedCard;

  public get $initStateModalSelectPayment(): DataStateModalPaymentInterface {
    return this._initStateModalSelectPayment;
  }

  public set $initStateModalSelectPayment(
    value: DataStateModalPaymentInterface
  ) {
    this._initStateModalSelectPayment = value;
  }

  public get $datePayment(): string {
    return this._datePayment;
  }

  public set $datePayment(value: string) {
    this._datePayment = value;
  }

  public get $valuePayment(): number {
    return this._valuePayment;
  }

  public set $valuePayment(value: number) {
    this._valuePayment = value;
  }

  public get $usefulExpirationDate(): Date {
    return this._usefulExpirationDate;
  }

  public set $usefulExpirationDate(value: Date) {
    this._usefulExpirationDate = value;
  }

  public get $usefulExpirationDateString(): string {
    return this._usefulExpirationDateString;
  }

  public set $usefulExpirationString(value: string) {
    this._usefulExpirationDateString = value;
  }

  public get $installmentsDescription(): string {
    return this._installmentsDescription;
  }

  public set $installmentsDescription(value: string) {
    this._installmentsDescription = value;
  }

  public get $numberOfInstallments(): string {
    return this._numberOfInstallments;
  }

  public set $numberOfInstallments(value: string) {
    this._numberOfInstallments = value;
  }

  public get $installmentsValue(): string {
    return this._installmentsValue;
  }

  public set $installmentsValue(value: string) {
    this.$installmentsValue = value;
  }

  public get $numberExtract(): string {
    return this._numberExtract;
  }

  public set $numberExtract(value: string) {
    this._numberExtract = value;
  }

  public get $totalValue(): string {
    return this._totalValue;
  }

  public set $totalValue(value: string) {
    this._totalValue = value;
  }

  public get $barCode(): string {
    return this._barCode;
  }

  public set $barCode(value: string) {
    this._barCode = value;
  }

  public get $customMinValue(): number {
    return this._customMinimumValue;
  }

  public set $customMinValue(value: number) {
    this._customMinimumValue = value;
  }

  public get $paymentTypeFlow(): string {
    return this._paymentTypeFlow;
  }

  public set $paymentTypeFlow(value: string) {
    this._paymentTypeFlow = value;
  }

  public get $pdf(): string | string[] {
    return this._pdf;
  }

  public set $pdf(value: string | string[]) {
    this._pdf = value;
  }

  public get $avaiblePaymentCreditCard(): IPaymentDetails {
    return this._avaiblePaymentCreditCard;
  }

  public set $avaiblePaymentCreditCard(value: IPaymentDetails) {
    this._avaiblePaymentCreditCard = value;
  }

  public get $securePayment(): PagSeguroInterface {
    return this._securePayment;
  }

  public set $securePayment(value: PagSeguroInterface) {
    this._securePayment = value;
  }

  public get $payloadHash(): string {
    return this._payloadHash;
  }

  public set $payloadHash(value: string) {
    this._payloadHash = value;
  }

  public get $dataParcel(): DataParcel {
    return this._dataParcel;
  }

  public set $dataParcel(value: DataParcel) {
    this._dataParcel = value;
  }

  public get $booklet(): IBoletoCarne {
    return this._booklet;
  }

  public set $booklet(value: IBoletoCarne) {
    this._booklet = value;
  }

  public get initialState() {
    return this._modalService.config.initialState;
  }
}
