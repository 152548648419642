import { Component, Input, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Alert } from '../../models/alerts.model';
@Component({
  selector: 'app-generic-alert',
  templateUrl: './generic-alert.component.html',
  styleUrls: ['./generic-alert.component.scss']
})
export class GenericAlertComponent implements OnInit, OnChanges {

  @Input() public configAlert: Alert;
  @Input() public dataTest?: string;
  public messageAlertError: string;
  public alertType: string;
  public alertPathIcon: string;

  constructor() { }

  ngOnInit() {
    this.mountConfigAlert();
  }

  ngOnChanges(changes: SimpleChanges) {

    const { title, type, pathIcon } = changes.configAlert.currentValue;

    if (title) {
        this.messageAlertError = title;
    }

  }

  public mountConfigAlert(): void {
    const { title, type, pathIcon } = this.configAlert;
    this.messageAlertError = title;
    this.alertType = type;
    this.alertPathIcon = pathIcon;
  }

}
