// CORE
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';

// MODULES, PROVIDERS, TYPES E INTERFACES
import { AppRoutingModule } from './app.routing';
import { PartlsModule } from './shared/partls/partls.module';
import { LottieModule } from 'ngx-lottie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IConfig, provideNgxMask } from 'ngx-mask';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// SERVICES
import { HttpInterceptorService } from '@app/shared/interceptors/http-interceptor.service';

// REGISTER LOCALE
import localePTBR from '@angular/common/locales/pt';
registerLocaleData(localePTBR);

// COMPONENTS
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { BottomSheetWrapperComponent } from './core/components/bottom-sheet-wrapper/bottom-sheet-wrapper.component';

export function playerFactory() {
  return import('lottie-web');
}

const maskConfig: () => Partial<IConfig> = () => {
  return {
    validation: false,
    showMaskTyped: true,
  };
};

const COMPONENTS = [AppComponent, PageNotFoundComponent];

const STANDOALONE_COMPONENTS = [BottomSheetWrapperComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    PartlsModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    }),
    NgMultiSelectDropDownModule.forRoot(),
    RecaptchaV3Module,
    ...STANDOALONE_COMPONENTS,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha,
    },
    { provide: 'Window', useValue: window },
    provideNgxMask(maskConfig),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
