import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgxSpinnerModule } from 'ngx-spinner';

import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { LogoComponent } from './components/logo/logo.component';
import { VirtualAssistantComponent } from './components/virtual-assistant/virtual-assistant.component';
import { LoaderAppComponent } from './components/loader-app/loader-app.component';

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    LogoComponent,
    VirtualAssistantComponent,
    LoaderAppComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
  ],
  exports: [
    NavComponent,
    FooterComponent,
    LogoComponent,
    VirtualAssistantComponent,
    LoaderAppComponent,
  ]
})
export class PartlsModule { }
