import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { ICreditCard } from '@app/shared/models/credit-card.model';

@Injectable({
  providedIn: 'root',
})
export class CartaoService {
  constructor(private storage: StorageService) {}

  public saveDataCardStorage = (value: ICreditCard): void => {
    this.storage.cartaoSelecionado = value;
    this.storage.bin = value.bin;
    this.storage.tipoCartao = value.tipo;
    this.storage.numeroCartao = value.numeroCartao;
    this.storage.nomeCartao = value.nome;
    this.storage.moduloAtivo = value.tipo;
  };
}
