import { Injectable } from '@angular/core';
import { CarrouselFaturaDigitalModel } from '@app/shared/models/carrousel-fatura-digital.model';
import { Observable, of } from 'rxjs';

import { ComponentEventHandler } from '@app/core/abstract-classes/event-component-handler';
import { ModalDigitalInvoiceActions } from '@app/shared/enum/modal-digital-invoice-actions.enum';

@Injectable({
  providedIn: 'root'
})
export class DigitalInvoiceService extends ComponentEventHandler<ModalDigitalInvoiceActions, any> {

  constructor() {
    super();
   }

  public mountBannersCarrouselDigitalInvoice = (): Observable<Array<CarrouselFaturaDigitalModel>> => (
    of([
      {
        idItem: 'item-1',
        class: 'carousel-item active',
        imagePath: 'static/assets/icon/Basics/icon-item-1-carrousel-digital-invoice.svg',
        titleModal: `Faça adesão à Fatura Digital e conheça <br>
        uma nova forma de usar o seu cartão.`,
        descriptionModal: `Mantenha sua <strong>fatura em dia</strong> e receba desconto em sua
        <strong>Anuidade Diferenciada (AD).</strong>`,
      },
      {
        idItem: 'item-2',
        class: 'carousel-item',
        imagePath: 'static/assets/icon/Basics/icon-item-2-carrousel-digital-invoice.svg',
        titleModal: `Ao ser um cliente digital você deixa de receber sua fatura via Correios.`,
        descriptionModal: `Todas as faturas dos seus cartões serão encaminhadas via
        <strong>SMS e/ou E-mail.</strong>`,
      },
      {
        idItem: 'item-3',
        class: 'carousel-item',
        imagePath: 'static/assets/icon/Basics/icon-item-3-carrousel-digital-invoice.svg',
        titleModal: `Não se preocupe com atrasos na entrega da sua fatura.`,
        descriptionModal: `Acesse sua fatura em tempo real sempre que quiser do seu <strong>smartphone, tablet ou computador</strong>`,
      },
    ])
  )

}
