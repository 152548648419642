import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http-service.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private _http: HttpService
  ) { }

  public getRequest<T>(path: string, params?: any): Observable<T> {
    return this._http.get<T>(this._adjustPath(path), params)
      .pipe(
        catchError((error) => throwError(() => error))
      );
  }

  public postRequest<T>(path: string, data: any, params?: any): Observable<T> {
    return this._http.post<T>(this._adjustPath(path), data, params)
      .pipe(
        catchError((error) => throwError(() => error))
      );
  }

  private _adjustPath(path: string): string {
    return path.startsWith('/') ? `${environment.urlBase}${path}` : path;
  }

}
