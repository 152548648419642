import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlterModalService } from '@app/core/services/alter-modal.service';

@Component({
  selector: 'app-modal-sold-wallet',
  templateUrl: './modal-sold-wallet.component.html',
  styleUrls: ['./modal-sold-wallet.component.scss'],
})
export class ModalSoldWalletComponent {
  public soldWalletDescriptions: {
    tituloMensagem: string;
    descricaoMensagem: string;
  };

  constructor(
    private _alterModalService: AlterModalService,
    private _router: Router
  ) {
    this.soldWalletDescriptions = this._alterModalService
      .initialState as unknown as {
      tituloMensagem: string;
      descricaoMensagem: string;
    };
  }

  public modalHide = (): void => {
    this._alterModalService.modalHide();
    this._router.navigate(['/']);
  };
}
