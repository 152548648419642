import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  private readonly _key = 'sG8j!2K@p$q#lWfA';

  public encryptString(value: string) {
    const hash = CryptoJS.SHA1(value);
    const result = CryptoJS.enc.Base64.stringify(hash);
    return result;
  }

  public encryptMessage = (message: string): string =>
    CryptoJS.AES.encrypt(message, this._key).toString();

  public decryptMessage = (message: string): string =>
    CryptoJS.AES.decrypt(message, this._key).toString(CryptoJS.enc.Utf8);
}
