<app-modal-wrapper>
  <app-modal-body [class]="'modal-body d-flex flex-column align-items-center'">
    <img
      ngSrc="./static/assets/icon/Basics/icon-main-content-error.png"
      alt="Algo deu errado, tente novamente!"
      width="47"
      height="55"
      class="mb-5"
    />

    <h4 class="strong mb-1">{{ modalTexts.title }}</h4>

    <p class="text-center mb-5">{{ modalTexts.description }}</p>

    <button class="btn btn-primary" (click)="modalHide()">Fechar</button>
  </app-modal-body>
</app-modal-wrapper>
