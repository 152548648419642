import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-flag-payment',
  templateUrl: './status-flag-payment.component.html',
  styleUrls: ['./status-flag-payment.component.scss'],
})
export class StatusFlagPaymentComponent {
  @Input() public status: string | number;
  @Input() public category: 'detailsInvoice' | 'otherContext' = 'otherContext';
}
