import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AlterModalService } from '@app/core/services/alter-modal.service';
import { ModalPaymentService } from '@app/shared/services/modal-payment.service';

@Component({
  selector: 'app-modal-header',
  template: `
    <div
      class="modal-header"
      [ngClass]="
        hasLabel
          ? 'd-flex justify-content-between align-items-center'
          : 'd-flex justify-content-end'
      "
    >
      <ng-content></ng-content>

      <button
        *ngIf="hasButtonClose"
        type="button"
        class="btn-close"
        aria-label="Fechar modal"
        (click)="modalHide()"
      >
        <span aria-hidden="true">
          <img
            ngSrc="./static/assets/icon/Basics/btn_fechar.svg"
            width="18"
            height="18"
            class="float-right"
          />
        </span>
      </button>
    </div>
  `,
})
export class ModalHeaderComponent {
  constructor(
    private _alterModal: AlterModalService,
    private _modalPayment: ModalPaymentService
  ) {}
  @Output() public eventEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() public class?: string;
  @Input() public hasLabel?: boolean = false;
  @Input() public hasButtonClose?: boolean = true;
  @Input() public isFlowPayment?: boolean = false;
  @Input() public hasDispatchEvent: boolean = false;

  public modalHide = (): void => {
    this.hasDispatchEvent && this.eventEmmiter.emit(true);

    this.isFlowPayment
      ? this._modalPayment.modalHide()
      : this._alterModal.modalHide();
  };
}

@Component({
  selector: 'app-modal-body',
  template: `
    <div [class]="class">
      <ng-content></ng-content>
    </div>
  `,
})
export class ModalBodyComponent {
  @Input() class?: string;
}

@Component({
  selector: 'app-modal-footer',
  template: `
    <div [class]="class">
      <ng-content></ng-content>
    </div>
  `,
})
export class ModalFooterComponent {
  @Input() class?: string;
}

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent {
  constructor(private _alterModal: AlterModalService) {}

  public modalHide = (): void => {
    this._alterModal.modalHide();
  };
}
