<div class="resumo-fatura row d-none d-sm-flex d-md-flex d-lg-flex">
    <div class="col-12 text-center">
        <p class="titulo-empty">Ainda não existem faturas anteriores.</p>
    </div>
</div>

<div class="resumo-fatura row d-xs-flex d-sm-none d-md-none d-lg-none">
    <div class="col-12 text-center">
        <p class="titulo-empty">Ainda não existem faturas anteriores.</p>
    </div>
</div>