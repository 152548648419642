import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NgOptimizedImage } from '@angular/common';

import {
  ModalWrapperComponent,
  ModalHeaderComponent,
  ModalBodyComponent,
  ModalFooterComponent,
} from './modal-wrapper.component';

const COMPONENTS = [
  ModalWrapperComponent,
  ModalHeaderComponent,
  ModalBodyComponent,
  ModalFooterComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, NgOptimizedImage],
})
export class ModalWrapperModule {}
