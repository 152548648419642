import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GenericAlertComponent } from '../generic-alert.component';

@NgModule({
  declarations: [
    GenericAlertComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenericAlertComponent,
  ]
})
export class GenericAlertModule { }
