<nav class="navbar navbar-expand-lg" style="background-color: #2d2b30;">
  <div id="nav-container" class="d-flex align-items-center justify-content-between">
    <a class="d-flex align-items-center" routerLink="/" data-test="brand">
      <app-logo [type]="'portal'" [showVersion]="true"></app-logo>
      <app-logo [type]="'cred'"></app-logo>
    </a>

    <ng-container *ngIf="isFasTicketRouter">
      <button (click)="logout()" class="btn btn-secondary">
        <img src="../../../../static/assets/icon/Basics/exit.svg" alt="Sair" />
        Sair
      </button>
    </ng-container>
  </div>
</nav>
