import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent implements OnInit {

  // Em segundos
  @Input() timerLoading = 60;
  animationTimer: number = this.timerLoading;
  @Output() finishedProgress = new EventEmitter<boolean>();

  constructor(
  ) { }

  ngOnInit() {

    this.animationTimer = this.timerLoading;

    const interval = setInterval(() => {

        if (this.timerLoading === 1) {
          clearInterval(interval);
          this.finishedProgress.emit(true);
        }
        this.timerLoading = this.timerLoading - 1;

    }, 1000);

  }

  setStylesTransition() {
    const styles = {
      animation: `fill ease-in-out ${this.animationTimer}s`
    };
    return styles;
  }

}
