// CORE MODULES
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// MODULES APP
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxSpinnerModule  } from 'ngx-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GenericAlertModule } from '../core/components/generic-alert/module/generic-alert.module';

// PIPES
import { StatusFlagPaymentPipe } from './pipes/status-flag-payment.pipe';

// COMPONENTS
import { CarrouselCardsComponent } from './components/carrousel-cards/carrousel-cards.component';
import { LoadingProgressComponent } from './components/loading-progress/loading-progress.component';
import { ListEmptyInvoicesComponent } from './components/list-empty-invoices/list-empty-invoices.component';
import { StatusFlagPaymentComponent } from './components/status-flag-payment/status-flag-payment.component';
import { ModalFinalizaAdesaoComponent } from './components/modal-finaliza-adesao/modal-finaliza-adesao.component';
import { ModalInformationSecurityPolicyComponent } from './components/modal-information-security-policy/modal-information-security-policy.component';
import { ModalTrocaCartaoComponent } from '@app/shared/components/modal-trocacartao/modal-trocacartao.component';
import { ModalHeaderLgpdTermsComponent } from './components/modal-header-lgpd-terms/modal-header-lgpd-terms.component';

const PIPES = [
  StatusFlagPaymentPipe,
];

const COMPONENTS = [
  CarrouselCardsComponent,
  LoadingProgressComponent,
  StatusFlagPaymentComponent,
  ModalFinalizaAdesaoComponent,
  ListEmptyInvoicesComponent,
  ModalInformationSecurityPolicyComponent,
  ModalTrocaCartaoComponent,
  ModalHeaderLgpdTermsComponent,
];

const MODULES = [
  CollapseModule,
  BsDatepickerModule,
  TooltipModule,
  NgxSpinnerModule,
  NgxSkeletonLoaderModule,
  GenericAlertModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [
    ...MODULES,
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS,
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    ...MODULES
  ],
  providers: [
    ...PIPES,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
