import { Inject, Injectable } from '@angular/core';

import { IScript } from '../core/models/IScript';
import { IAlwm } from '@app/core/models/IAllowme';
@Injectable({
  providedIn: 'root',
})
export class FingerPrintService {
  private _scripts: IScript[] = [];

  constructor(@Inject('Window') private _window: Window) {}

  public allowmeCollect = (action: string): Promise<string> => {
    return new Promise((resolve, rejects) => {
      try {
        const fingerPrint: Partial<IAlwm> = this._window['Alwm'];
        resolve(fingerPrint.collect(action));
      } catch {
        rejects(new Error('Falha ao obter fingerPrint'));
      }
    });
  };

  public loadScript = (src: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (this._scripts.find((script) => script.src === src)) {
        // Script já carregado, não é necessário carregar novamente
       // resolve();
      } else {
        // Adiciona o script à lista de scripts
        this._scripts.push({ src, loaded: false });

        // Cria um elemento <script>
        const scriptElement = document.createElement('script');
        scriptElement.src = src;
        scriptElement.defer = true; // Define o script como 'defer'
        scriptElement.type = 'text/javascript'; // Define o tipo do script como 'text/javascript'

        // Define o evento onload para marcar o script como carregado
        scriptElement.onload = () => {
          const script = this._scripts.find((s) => s.src === src);
          script.loaded = true;
          resolve();
        };

        // Define o evento onerror para tratar erros de carregamento
        scriptElement.onerror = () =>
          reject(new Error('[AllowMe] Falha ao carregar o script.'));

        // Adiciona o elemento <script> ao final do corpo do documento
        document.body.appendChild(scriptElement);
      }
    });
  };
}
