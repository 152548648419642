import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { environment } from './../../../../environments/environment';

import { AlterModalService } from '../../../core/services/alter-modal.service';

@Component({
  selector: 'app-modal-header-lgpd-terms',
  templateUrl: './modal-header-lgpd-terms.component.html',
  styleUrls: ['./modal-header-lgpd-terms.component.scss']
})
export class ModalHeaderLgpdTermsComponent implements OnInit {

  private _enviromentVersion: string;
  private _pathLogo: string;
  public readonly defaultPahtLogo: string;
  public readonly mobilePahtLogo: string;

  constructor(
    private alterModalService: AlterModalService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.defaultPahtLogo = 'static/assets/images/logo_cred_background_black.svg';
    this.mobilePahtLogo = 'static/assets/images/logo_mobile_background_black.svg';
  }

  ngOnInit() {
    this.$enviromentVersion = this.getEnviromentVersion(environment);
    this.observerBreakpoint();
  }

  public getEnviromentVersion = ({ version }): string => version;

  public observerBreakpoint = (): void => {
    this.breakpointObserver.observe([
      '(max-width: 768px)'
        ]).subscribe(result => {
           this.setLogo(result.matches);
        });
  }

  public setLogo = (hasMobileDevice: boolean): void => {
    if (hasMobileDevice) {
        this.$pathLogo = this.mobilePahtLogo;
        return;
    }
    this.$pathLogo = this.defaultPahtLogo;
  }

  public modalHide = (): void => {
    this.alterModalService.modalHide();
  }

  public get $enviromentVersion(): string {
    return this._enviromentVersion;
  }

  public set $enviromentVersion(value: string) {
    this._enviromentVersion = value;
  }

  public get $pathLogo(): string {
    return this._pathLogo;
  }

  public set $pathLogo(value: string) {
    this._pathLogo = value;
  }

}
