import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() public type:
    | 'cred'
    | 'portal'
    | 'portalModalHeader'
    | 'dashboardMobile' = 'portal';
  @Input() public showVersion: boolean = false;
  public imageSource: string;
  public version = environment.version;
  public readonly pathFolder: string = './static/assets/images';
  private _setLogoType: Record<
    'portal' | 'portalModalHeader' | 'dashboardMobile' | 'cred',
    (path?: string) => void
  > = {
    portal: () => this._setImage('logo_portal_cartao.svg'),
    portalModalHeader: () => this._setImage('portal_do_cartao_blakc.svg'),
    dashboardMobile: () => this._setImage('logo_dashboard_mobile.svg'),
    cred: (path: 'brasao_cred.svg' | 'logo_cred.svg' = 'logo_cred.svg') =>
      this._setImage(path),
  };

  constructor(private _breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this._observerBreakpoint();
  }

  private _observerBreakpoint = (): void => {
    this._breakpointObserver
      .observe(['(max-width: 991px)'])
      .subscribe(({ matches }: BreakpointState) => {
        matches && this.type === 'cred'
          ? this._setLogoType['cred']('brasao_cred.svg')
          : this._setLogoType[this.type]();
      });
  };

  private _setImage = (image: string): void => {
    this.imageSource = `${this.pathFolder}/${image}`;
  };
}
