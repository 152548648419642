import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
});

// Código comentado para evitar erro de Uncaught SyntaxError: Strict mode code may not include a with statement após migração para Angular 9
// export { renderModule, renderModuleFactory } from '@angular/platform-server';
