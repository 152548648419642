import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  public isAccessingDashboard: boolean = false;

  constructor(
    private _router: Router,
  ) { }

  ngOnInit() {
    this.isAccessingDashboard = this._checkAccessDashboard();
  }

  private _checkAccessDashboard = (): boolean => this._router.url.includes('dashboard');

}
