import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

import { TypeModulesContext } from '@app/core/enum/type-modules-context.enum';

@Injectable({
  providedIn: 'root',
})
export class RouterControlService {
  constructor(private _storage: StorageService) {}

  public getRouteContext = (): string => {
    return this.dispatchRouter(this._storage.moduloSelecionado)
  };

  public dispatchRouter = (context: string): string => {
    const routerContext: Record<TypeModulesContext, string> = {
      EXTRATO: '/dashboard/faturas',
      CARNE: '/dashboard/carnes',
      NEGOCIACAO: '/dashboard/negociacao/dividas/aberto',
      ACORDO_ANDAMENTO: '/dashboard/negociacao/acordos',
      OUTROS_SERVICOS: '/dashboard/servicos',
      SEGUROS: '/dashboard/seguros'
    };

    return routerContext[context];
  };
}
