import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { VERSION } from './app.api';
import { TypeModulesContext } from '@app/core/enum/type-modules-context.enum';
import { BasePathRouterModulesEnum } from '@app/shared/utils/base-path-init-router-modules';

import { FingerPrintService } from './services/fingerprint.service';
import { StorageService } from '@app/services/storage.service';
import { GoogleTagManager } from './core/utils/google-tag-manager';

import { SharedModule } from './shared/shared.module';

import { environment } from '@env/environment';
import { IAlwm } from './core/models/IAllowme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SharedModule],
})
export class AppComponent implements OnInit {
  public isAccessingDashboard: boolean;

  constructor(
    private _fingerService: FingerPrintService,
    private _router: Router,
    private _storageService: StorageService,
    private _renderer: Renderer2,
    @Inject('Window') private _window: Window
  ) {}

  ngOnInit() {
    this._getActivatedRouter();

    this._loadScript(
      'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js'
    );

    this._loadScript(
      'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js'
    );

    GoogleTagManager.loadScript();

    this._loadAllowmeScript();

    this._consoleVersion();
  }

  private _consoleVersion = (): void => {
    console.log(`version: v${VERSION}`);
  };

  private _getActivatedRouter = (): void => {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => {
        this.isAccessingDashboard = this._checkAccessDashboard(url);
        this._checkActiveModule(url);
      });
  };

  private _checkActiveModule = (url: string): void => {
    if (url.includes(BasePathRouterModulesEnum.BOOKLET)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_BOOKLET);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.EXTRACT)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_EXTRACT);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.NEGOTIATION)) {
      this._setActiveModule(
        TypeModulesContext.TYPE_MODULES_CONTEXT_NEGOTIATION
      );
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.AGREEMENT)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_AGREEMENT);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.INSURANCE)) {
      this._setActiveModule(TypeModulesContext.TYPE_MODULES_CONTEXT_INSURANCE);
      return;
    }

    if (url.includes(BasePathRouterModulesEnum.OTHER_SERVICES)) {
      this._setActiveModule(
        TypeModulesContext.TYPE_MODULES_CONTEXT_OTHER_SERVICES
      );
      return;
    }
  };

  private _setActiveModule = (value: string): void => {
    this._storageService.moduloAtivo = value;
  };

  private _checkAccessDashboard = (url: string): boolean =>
    url.includes('dashboard');

  private _loadScript = (src: string): void => {
    try {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = true;
      script.src = src;
      this._renderer.appendChild(document.body, script);
    } catch (error) {
      console.log('Error' + error);
    }
  };

  private _loadAllowmeScript = (): void => {
    this._fingerService
      .loadScript(`${environment.allowmeUrl}`)
      .then(() => {
        const allowmw: IAlwm = this._window['Alwm'];

        allowmw
          .init({ apiKey: environment.allowmeKey })
          .then(() => {
            console.log('[AllowMe] Inicializado com sucesso!');
          })
          .catch((error) => {
            console.error('[AllowMe] Falha na inicialização:', error);
          });
      })
      .catch((error: Error) => {
        console.log(error.message);
      });
  };
}
