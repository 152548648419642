<div class="container text-center">
  <header class="modal-header">
  </header>
  <div class="modal-body">
    <div class="content-modal">
      <div class="icon">
        <img class="icon" [src]="iconEmptyListCards" alt="Não possui cartões">
      </div>
      <div class="title mt-6">
        <p>Você não possui cartão disponível no momento.</p>
      </div>
      <div class="description mt-3">
        <p>Iremos te direcionar para o início, mas você poderá tentar novamente mais tarde.</p>
      </div>
    </div>
  </div>
  <footer class="footer-modal text-center">
    <button type="button" class="btn btn-primary btn-confirm" (click)="hideModal()">OK!</button>
  </footer>
</div>