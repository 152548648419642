import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { ModalWrapperModule } from '@app/shared/components/modal-wrapper/modal-wrapper.module';

import { AlterModalService } from '@app/core/services/alter-modal.service';

@Component({
  selector: 'app-modal-error',
  standalone: true,
  imports: [CommonModule, ModalWrapperModule, NgOptimizedImage],
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
  public modalTexts: { title: string; description: string } = {
    title: 'Algo deu errado!',
    description:
      'Infelizmente não foi possível completar a sua requisição neste momento. Tente mais tarde.',
  };

  constructor(private _alterModal: AlterModalService) {
    this.modalTexts = {
      ...this.modalTexts,
      ...(this._alterModal.initialState as unknown as {
        title: string;
        description: string;
      }),
    };
  }

  public modalHide = (): void => {
    this._alterModal.modalHide();
  };
}
