import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';

import { Observable } from 'rxjs';

import { CarrouselFaturaDigitalModel } from '@app/shared/models/carrousel-fatura-digital.model';
import { DigitalInvoiceService } from '@app/services/digital-invoice.service';

declare var jQuery: any;

@Component({
  selector: 'app-modaladesao',
  templateUrl: './modaladesao.component.html',
  styleUrls: ['./modaladesao.component.scss'],
  providers: [],
})
export class ModalAdesaoComponent implements OnInit, AfterViewInit {
  @Output() public eventEmmiter: EventEmitter<boolean> = new EventEmitter();
  public interval;
  public dataCarrouselDigitalInvoice$: Observable<
    CarrouselFaturaDigitalModel[]
  >;

  constructor(private _digitalInvoiceService: DigitalInvoiceService) {}

  ngAfterViewInit() {
    this._setTimeOutCarrousel(500);
  }

  ngOnInit() {
    this.dataCarrouselDigitalInvoice$ =
      this._digitalInvoiceService.mountBannersCarrouselDigitalInvoice();
  }

  private _setTimeOutCarrousel = (setTime: number) => {
    const carousel = jQuery('#carousel-indicators');
    carousel.carousel({
      inteval: setTime,
    });
  };

  public confirmDigitalInvoice = (): void => {
    this.eventEmmiter.emit(true);
  };
}
