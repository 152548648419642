export enum InvoiceStatusEnum {
  PAGO = 'PAGO',
  ABERTO = 'ABERTO',
  FATURAABERTA = 'FATURAABERTA',
  ATRASO = 'ATRASO',
  EMATRASO = 'EMATRASO',
  EMABERTO = 'EMABERTO',
  REFINANCIADO = 'REFINANCIADO',
  VENCEHOJE = 'VENCEHOJE',
}
