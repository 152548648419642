import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';

import { ICreditCard } from '@app/shared/models/credit-card.model';
import { Utils } from '@app/core/utils/utils';

import { DigitalInvoiceService } from '@app/services/digital-invoice.service';
import { CartaoService } from '@app/services/cartao-service.service';

import { ModalDigitalInvoiceActions } from '@app/shared/enum/modal-digital-invoice-actions.enum';

@Component({
  selector: 'app-carrousel-cards',
  templateUrl: './carrousel-cards.components.html',
  styleUrls: ['./carrousel-cards.component.scss']
})
export class CarrouselCardsComponent implements OnChanges {

  @Output() public cardSelect = new EventEmitter();
  @Input() public listCards: Array<ICreditCard>;

  constructor(
    private _digitalInvoiceService: DigitalInvoiceService,
    private _cartaoService: CartaoService,
  ) { }

  ngOnChanges() {
    this.listCards = Utils.setCards(this.listCards);
  }

  public selectItem = (card: ICreditCard): void => {
    this._cartaoService.saveDataCardStorage(card);
    this._dispatchModalDigitalInvoiceAction(ModalDigitalInvoiceActions.MODAL_DIGITAL_INVOICE_SELECT_CARD, null);
    this.cardSelect.emit(card);
  }

  private _dispatchModalDigitalInvoiceAction = (action: ModalDigitalInvoiceActions, data: any): void => {
    this._dispatch(action, data);
  }

  private _dispatch = (action: ModalDigitalInvoiceActions, data: any): void => {
    this._digitalInvoiceService.dispacthAction({ action, data });
  }


}
